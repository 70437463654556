import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    // exact: true, 
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login/Index")),
  }, 
   {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About Us/AboutUs")),
  },
  {
    exact: true,
    path: "/policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/About Us/Policy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/About Us/Terms")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/About Us/FAQ")),
  },
  {
    exact: true,
    path: "/market",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Market/Index")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dasboard/Index")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/EditProfile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/EditProfile/EditProfile")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword/index")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notification/Notification")),
  },
  {
    exact: true,
    path: "/transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Transactions/Transaction")),
  },
  {
    exact: true,
    path: "/profit-opportunities",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profit/Index")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/ContactUs")),
  },
  {
    exact: true,
    path: "/404",
    layout: HomeLayout,
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
