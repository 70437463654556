export const NetworkContextName = "Polygon Mainnet Network";
export const ACTIVE_NETWORK = 137;
export const NftContractAddress = "0x122E82B31A7C13137d455fC45C1D7484c5846168";
export const NftStakingAddress = "0x06192323Fb5F19280B0FEb66eE4B98F0AAb9e826";

export const providerPolygon = "0xd05e3E715d945B59290df0ae8eF85c1BdB684744";


// register contract address
export const contractRegister = {
  polygon: "0xcA228877B58E0EF50Be734254EE3375Db2c9301e",
  arbitrum: "0xcA228877B58E0EF50Be734254EE3375Db2c9301e",
  binance: "0xcA228877B58E0EF50Be734254EE3375Db2c9301e",
};

export const RPC_URL = "https://polygon.drpc.org";

export const NetworkDetailsBNB = [
  {
    chainId: "0x38",
    chainName: "BNB Mainnet Network",
    nativeCurrency: {
      name: "BNB Mainnet Network",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
];

export const NetworkDetailsMumbai = [
  {
    chainId: "0x13881",
    chainName: "Polygon Mumbai Test Network",
    nativeCurrency: {
      name: "Polygon Mumbai Test Network",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-mumbai-pokt.nodies.app"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
];
export const NetworkDetailsPOLY = [
  {
    chainId: "0x89",
    chainName: "Polygon Mainnet Network",
    nativeCurrency: {
      name: "MATIC",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://polygon.drpc.org"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
export const NetworkDetailsARB = [
  {
    chainId: "0xA4B1",
    chainName: "Arbitrum One Network",
    nativeCurrency: {
      name: "Arbitrum One ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arbitrum.drpc.org"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
export const TokeImgList = [
  {
    name: "DAI",
    image: "/images/coin/DAI.webp",
  },
  {
    name: "WETH",
    image: "/images/coin/eth.webp",
  },
  {
    name: "USDC",
    image: "/images/coin/usdc.webp",
  },
  {
    name: "WOO",
    image: "/images/coin/woo.webp",
  },
  {
    name: "UNI",
    image: "/images/coin/uni.webp",
  },
  {
    name: "ETH",
    image: "/images/coin/eth.webp",
  },
  {
    name: "USDT",
    image: "/images/coin/TetherUSDT.webp",
  },
  {
    name: "SUSHI",
    image: "/images/coin/SUSHI.webp",
  },
  {
    name: "SUSHITokenUni",
    image: "/images/coin/SUSHI.webp",
  },
  {
    name: "AAVE",
    image: "/images/coin/AAVE.webp",
  },
  {
    name: "KCS",
    image: "/images/coin/kucointoken.webp",
  },
  {
    name: "MANA",
    image: "/images/coin/mana.webp",
  },
  {
    name: "HT",
    image: "/images/coin/ht.webp",
  },
  {
    name: "DappRadar",
    image: "/images/coin/dappradar.webp",
  },
  {
    name: "HBTC",
    image: "/images/coin/huobibtc.webp",
  },
  {
    name: "WBTC",
    image: "/images/coin/wBTC.webp",
  },
  {
    name: "WMATIC",
    image: "/images/coin/wMatic_32.webp",
  },
];

export function findImageByName(nameToFind, network) {
  const foundItem = TokeImgList.find((item) => item.name === nameToFind);
  if (foundItem) {
    return foundItem.image;
  } else {
    return `/images/coin/empty-token-${
      network === 137
        ? "poly"
        : network === 1
        ? "eth"
        : network === 42161
        ? "arb"
        : "eth"
    }.webp`;
  }
}

export const imgBackground = [
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(254, 202, 82, 1) 0%,  rgba(255, 123, 2, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(86, 129, 241, 1) 0%,  rgba(19, 84, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(192, 101, 222, 1) 0%,  rgba(96, 42, 252, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(0, 175, 250, 1) 0%,  rgba(75, 255, 212, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(38, 99, 255, 1) 0%,  rgba(255, 75, 162, 1)",
  },
  {
    background:
      "linear-gradient(to right, rgba(243, 54, 88, 1) 0%,  rgba(29, 41, 149, 1)",
  },
];
export const swichNetworkHandler = async (network) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + network.toString(16) }],
    });
    return network;
  } catch (error) {
    console.log("ERROR", error);
    if (error.code === 4902) {
      addNetworkHandler(network);
    }
  }
};

export const addNetworkHandler = async (network) => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails(network),
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};

const NetworkDetails = (network) => {
  switch (network?.toString()) {
    case "137":
      return NetworkDetailsPOLY;
    case "42161":
      return NetworkDetailsARB;
    case "56":
      return NetworkDetailsBNB;
    case "80001":
      return NetworkDetailsMumbai;
    default:
  }
};
