import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { toast } from "react-toastify";
import { injected } from "src/connectors";
import axios from "axios";
// import ApiConfig from "src/config/APICongig";
import { getAPIHandler } from "src/config/service";
import ApiConfig from "src/config/APICongig";

export const UserContext = createContext();

// const setSession = (userAddress) => {
//   if (userAddress) {
//     sessionStorage.setItem("userAddress", userAddress);
//   } else {
//     localStorage.removeItem("userAddress");
//   }
// };

const setSession = (outToken, clientToken) => {
  console.log(" ----- response?.data?.result?.outToken ", outToken);
  console.log(" ----- response?.data?.result?.clientToken ", clientToken);
  if (outToken) {
    localStorage.setItem("outToken", outToken);
  } else {
    // localStorage.removeItem("outToken");
    // delete axios.defaults.headers.common.Authorization;
  }
  if (clientToken) {
    localStorage.setItem("clientToken", clientToken);
  } else {
    // localStorage.removeItem("clientToken");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("outToken");
  return accessToken ? true : false;
}
// function checkLogin() {
//   const accessToken = window.localStorage.getItem("token");
//   return accessToken ? true : false;
// }
export default function AuthProvider(props) {
  const { activate, account, library, chainId, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isMetaMask, setIsMetaMask] = useState(false);
  const [userData, setUserData] = useState({});
  const [wallectLoader, setWallectLoader] = useState(false);
  const [walletErr, setWallectErr] = useState(false);

  const disconnectWallte = async () => {
    deactivate();
  };
console.log(" -------- userData ", userData)


  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;
      console.log(connector.walletConnectProvider?.wc?.uri);
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          localStorage.removeItem("walletName");
          activate(connector);
        }
      });
      console.log(connector.walletConnectProvider?.wc?.uri);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === localStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    } // eslint-disable-next-line
  }, []);

  const getUserDetail = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getUserDetail,

        headers: {
          token: window.localStorage.getItem("outToken"),
        },
      });
      if (res.status === 200) {
        setUserData(res.data.data);
      } else {
        // toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(error.message);
    }
  };

  useEffect(() => {
    // data.updateUser(account);
    if (isLogin) {
      getUserDetail();
    }
  }, [isLogin]); //eslint-disable-line
  // useEffect(() => {
  //   if (account) {
  //     connectWalletAPICall();
  //   } else {
  //     setIsLogin(false);
  //     setTokenSession(null);
  //   }
  // }, [account]);

 

  let data = {
    account,
    walletErr,
    setWallectErr,
    wallectLoader,
    setWallectLoader,
    library,
    chainId,
    userData,
    isLogin,
    isMetaMask,
    getUserDetail: () => {
      getUserDetail();
    },
      
    userLogIn: (type, outToken, clientToken) => {
      setSession(outToken, clientToken);
      setIsLogin(type);
    },
    connectWallet: (id) => {
      console.log("-----network");
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
          setIsMetaMask(true);
          console.log("connectWallet");
          // deployTransaction();
          console.log("error---", error);
        }
      });
    },
    dicconectWalletFun: () => {
      disconnectWallte();
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
